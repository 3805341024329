import React, { Component, Fragment } from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom"
import Helmet from "react-helmet"
import TagManager from "react-gtm-module"
import Meta from "./components/Meta"
import styled from "styled-components"
import HomePage from "./pages/home"
import ServiceWorkerNotifications from "./components/ServiceWorkerNotifications"
import data from "./data.json"

const {
  REACT_APP_GTM: GTM,
} = process.env

const tagManagerArgs = {
  gtmId: GTM,
}

TagManager.initialize(tagManagerArgs)

const RouteWithMeta = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={routeProps => (
      <Fragment>
        <Meta {...props} />
        <Component {...routeProps} {...props} />
      </Fragment>
    )}
  />
)

class App extends Component {

  render() {
    const mssb = data.mssb[0]
    debugger
    const context = {
      ...mssb,
    }
    
    return (
      <AppContainer>
        
        <Router>
          <ServiceWorkerNotifications reloadOnUpdate />
          <Helmet
            defaultTitle={"Midshelf String Band"}
            titleTemplate={`Midshelf String Band`}
          />
          <Switch>
            <RouteWithMeta
              path="/"
              exact
              component={HomePage}
              context={context}
            />
          </Switch>
        </Router>
      </AppContainer>
    )
  }
}

export default App

const AppContainer = styled.div`
  min-height: 100%;
  min-width: 100%;
  position: absolute;

  .no-child-pointer-events * {
    pointer-events: none;
  }
`
