import React, { useEffect } from "react"
import styled from "styled-components"
import TagManager from "react-gtm-module"
import { getGTM } from "../util/gtm_utils"
import PageShell from "./page_shell"
import Gallery from "react-grid-gallery"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import { Element } from "react-scroll"
// import axios from "axios"

const HomePage = ({ context }) => {
  // on component mount
  useEffect(() => {
    TagManager.dataLayer(getGTM())
  }, [])

  const {
    bandhero,
  } = context

  return (
    <PageShell context={context}>
      <BodyContainer>
        <div className="intro-section">
          <img src={bandhero} alt="band hero" />
        </div>

        <IntroSection context={context} />
        <PicturesSection context={context} />
        <MusicSection context={context} />
        <ScheduleSection context={context} />
        <BookSection context={context} />
      </BodyContainer>
    </PageShell>
  )
}

const BodyContainer = styled.div`
  margin-top: 10px;
  color: white;

  .intro-section {
    img {
      width: 100%;
    }
  }
`

const PicturesSection = ({ context }) => {
  const {
    images,
  } = context

  const PicturesContainer = styled.div`
    background-color: #313131;
    padding: 20px;
  `
  if (images.length < 1) {
    return null
  }

  return (
    <Section>
      <PicturesContainer>
        <Gallery images={images} />
      </PicturesContainer>
    </Section>
  )
}

const ScheduleSection = ({ context }) => {
  const ScheduleContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 1.2em;
    color: white;

    .gig {
      margin-bottom: 20px;
      background-color: #313131;
      padding: 30px;
      border-radius: 20px;
      width: 550px;
    }

    .no-shows {
    }

    .detail {
    }
  `

  const {
    schedule,
    noshows,
  } = context

  return (
    <Element id="schedule">
      <Section title="Shows">
        <ScheduleContainer>
          {schedule.length > 0 &&
            schedule.map(s => (
              <div className="gig">
                <div>
                  <b>{s.location}</b>
                </div>
                <div className="detail">
                  <div>
                    {s.date}, {s.time}
                  </div>
                  <div>{s.address1}</div>
                  <div>{s.address2}</div>
                  {s.note && <div><br/>{s.note}</div>}
                </div>
              </div>
            ))}
          {schedule.length < 1 && <div className="no-shows">{noshows}</div>}
        </ScheduleContainer>
      </Section>
    </Element>
  )
}

const BookContainer = styled.div`
    display: flex;
    flex-direction: row
    align-items: center;
    justify-content: center;

    font-size: 1.2em;
    color: white;
    .mailto {
      color: #d9ff63;
      font-weight: bold;
    }
    form {
        margin: 10px;
        width: 70%;
        input, textarea {
          width: 100%;
        }
        textarea {
          height: 200px;
        }
    }
    .form-group {
      margin-bottom: 10px;
    }
`

const BookSection = ({context}) => {
  const {contactText, contactSubject} = context
  const subject = contactSubject || "Hi there!"
  return (
    <Element id="book">
      <Section title="Contact">
        <BookContainer>
          <form
            noValidate={true}
            id="contact-form"
            method="POST"
          >
            <div className="form-group">
              <a class="mailto" href={`mailto:info@midshelfstringband.com?subject=${subject}`}>Contact us!</a> {contactText}
            </div>
          </form>
        </BookContainer>
      </Section>
    </Element>
  )
}

const MusicSection = ({ context }) => {
  const MusicContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .song {
      margin: 20px;
    }
  `

  const {
    music,
  } = context
  return (
    <Element id="music">
      <Section title={"Music"}>
        <MusicContainer>
          {music.map(m => (
            <div className="song">
              <div>
                <b>{m.title}</b> by {m.artist}
              </div>
              <AudioPlayer src={m.src} />
            </div>
          ))}
        </MusicContainer>
      </Section>
    </Element>
  )
}

const IntroSection = ({ context }) => {
  const AboutTextContainer = styled.div`
    font-size: 1.2em;
    color: white;
    text-align: center;
  `
  return (
    <Section title={"About"}>
      <Element id="about">
        <AboutTextContainer>{context.about}</AboutTextContainer>
      </Element>
    </Section>
  )
}

const Section = ({ title, children }) => {
  return (
    <SectionContainer>
      {title && <SectionTitleContainer>{title}</SectionTitleContainer>}
      {children}
    </SectionContainer>
  )
}

const SectionContainer = styled.section`
  margin-top: 90px;
  display: flex;
  flex-direction: column;
`

const SectionTitleContainer = styled.section`
  font-size: 1.5em;
  color: #fe53bb;
  text-align: center;
  font-weight: bold;
`

export default HomePage
