import React from "react"
import styled from "styled-components"
import { Link } from "react-scroll"

const links = [
  {
    name: "About",
    to: "about",
  },
  {
    name: "Music",
    to: "music",
  },
  {
    name: "Shows",
    to: "schedule",
  },
  {
    name: "Contact",
    to: "book",
  },
]

const PageShell = ({ context, children }) => {
  const {
    bandlogo,
  } = context
  const year = new Date().getFullYear()
  const footerText = `© ${year} Midshelf String Band`
  return (
    <PageShellContainer>
      <div className="top-border" />
      <div className="main-content">
        <div className="headerContainer">
          <HeaderContainer>
            <div className="logo">
              <img src={bandlogo} alt="band logo" />
            </div>
          </HeaderContainer>
          <Header context={context} />
        </div>

        <div className="bodyContainer">{children}</div>

        <div className="footer">
          <hr />
          <div>
            {footerText}
            {links.map(l => (
              <span>
                {" "}
                |{" "}
                <Link
                  className="nav-item-footer"
                  activeClass="active"
                  to={l.to}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  {l.name}
                </Link>
              </span>
            ))}
          </div>
        </div>
      </div>
    </PageShellContainer>
  )
}

const Header = () => {
  return (
    <HeaderContainer>
      <div className="nav">
        {links.map(l => (
          <Link
            className="nav-item"
            activeClass="active"
            to={l.to}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {l.name}
          </Link>
        ))}
      </div>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;

    .logo {
        display: flex;
        flex-direction: row
        align-items: center;
        justify-content: center;
    }

    .nav {
        display: flex;
        flex-direction: row
        align-items: center;
        justify-content: center;
      
        .nav-item {
            background-color: green;
            padding: 10px;
            margin: 10px;
            border-radius: 15px;
            background-color: #FE53BB;
            font-weight: bold;
            color: white;
        }
    }
`

const PageShellContainer = styled.div`
  .top-border {
      background-color: #fe53bb;
      width: 100%;
      height: 7px;
  }
  .main-content {
    display: flex;
    flex-direction: column;
    padding: 10px 20px 20px 20px;
    margin-left: 15%;
    margin-right: 15%;

    .headerContainer {
      flex: 0.8;
    }

    .bodyContainer {
      flex: 0.2;
    }

    .footer {
      hr {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
      }

      color: white;

      .nav-item-footer {
        color: #fe53bb;
      }
    }
  }
`
export default PageShell
